import { FilledButton } from 'Atoms/buttons/FilledButton';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledBackButton = styled(FilledButton)`
  position: fixed;
  top: 15px;
  left: 10px;
  z-index: 1000;
  width: auto; /* Adjust to fit content */
  padding: 5px 12px;
  font-size: 12px;
  display: flex;
`;

export const BackButton: FC = () => {
  const navigate = useNavigate();

  return (
    <StyledBackButton padding="small" onClick={() => navigate(-1)}>
      Back
    </StyledBackButton>
  );
};
