import { Loader } from 'Atoms/Loader';
import { GlobalStylePDF } from 'globalStyle';
import { Navbar } from 'Organisms/Navbar';
import { ReactElement, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useEBook, useEBooksList } from 'store/eBookStore/hooks';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

const FrameStyled = styled.iframe`
  width: 100%;
  height: 100%;

  border: none;
  padding: 0;
  margin: 0;
`;

export const PDF = (): ReactElement => {
  const location = useLocation();

  const title = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('title');
  }, [location.search]);

  const year = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('year');
  }, [location.search]);

  const eBooks = useEBooksList(year);

  const eBookInfo = useMemo(
    () => (!isLoading(eBooks) ? eBooks.find(eb => eb.title === title) || null : 'Loading'),
    [eBooks, title]
  );

  const eBook = useEBook(eBookInfo, year);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  if (isLoading(eBook)) {
    return <Loader />;
  } else if (!eBook) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <GlobalStylePDF />
      <Helmet>
        <title>{`${eBook.bookTitle} - GIDEON eBooks`}</title>
      </Helmet>
      {isIOS && <Navbar />}
      <FrameStyled src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(eBook.eBookURL)}`} />
    </>
  );
};
