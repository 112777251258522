import { ReactComponent as DarkModeIcon } from 'assets/UI/DarkMode.svg';
import { ReactComponent as LightModeIcon } from 'assets/UI/LightModeDark.svg';
import { ReactComponent as LogOutIcon } from 'assets/UI/Logout.svg';
import { ReactComponent as LogOutIconDark } from 'assets/UI/LogoutDark.svg';
import { ReactComponent as SwitchIconDark } from 'assets/UI/SwitchDark.svg';
import { ReactComponent as SwitchIconLight } from 'assets/UI/SwitchLight.svg';
import { BackButton } from 'Molecules/BackButton';
import { NavbarLogo } from 'Molecules/NavbarLogo';
import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { ThemeModeContext } from 'services/theme';
import { useMobile } from 'services/useMobile';
import { useAccountController } from 'store/accountStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';
import { AccountMenuOption } from 'types/accountMenuOption';
import { isNotNull } from 'types/guards';

import { AccountMenu } from './AccountMenu';

const Navigation = styled.nav`
  width: 100%;
  background: ${props => props.theme.colors.background.navbar};
  box-shadow: 0 6px 3px -3px rgba(0, 0, 0, 0.3);
  height: 64px;
  min-height: 64px;
  z-index: 800;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 11px 0 32px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    justify-content: flex-start;
    padding: 0 20px;
  }
`;

export const Navbar: FC = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeModeContext);
  const toggleTheme = useCallback(() => setIsDarkMode(!isDarkMode), [isDarkMode, setIsDarkMode]);
  const { signOut } = useAccountController();
  const theme = useContext(ThemeContext);

  const isMobile = useMobile('m');
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  const options = useMemo(() => {
    const darkModeOption: AccountMenuOption = isDarkMode
      ? {
          value: 'light-mode',
          label: 'Light mode',
          Icon: LightModeIcon,
          onClick: () => toggleTheme(),
          description: 'Dark mode is activated, click to activate light mode',
        }
      : {
          value: 'dark-mode',
          label: 'Dark mode',
          Icon: DarkModeIcon,
          onClick: () => toggleTheme(),
          description: 'Light mode is activated, click to activate dark mode',
        };

    const appUrl = process.env.REACT_APP_APP_URL;
    const appOption: AccountMenuOption | null = appUrl
      ? {
          value: 'app',
          label: 'Back to database',
          onClick: () => (window.location.href = appUrl),
          Icon: isDarkMode ? SwitchIconDark : SwitchIconLight,
        }
      : null;

    const help: AccountMenuOption = {
      value: 'help',
      label: 'Help',
      Icon: theme.images.help,
      onClick: () => (window.location.href = 'https://learn.gideononline.com'),
    };

    const signOutOption: AccountMenuOption = {
      value: 'Sign-out',
      label: 'Sign out',
      Icon: isDarkMode ? LogOutIconDark : LogOutIcon,
      onClick: signOut,
    };

    return [darkModeOption, appOption, help, signOutOption].filter(isNotNull);
  }, [isDarkMode, signOut, theme.images.help, toggleTheme]);

  const content = useMemo((): ReactNode | null => {
    if (isIOS) {
      return (
        <>
          <BackButton />
          <NavbarLogo to="/" />
          <AccountMenu options={options} />
        </>
      );
    } else if (isMobile) {
      // Authed mobile
      return (
        <>
          <NavbarLogo to="/" />
          <AccountMenu options={options} />
        </>
      );
    } else {
      // Authed desktop
      return (
        <>
          <NavbarLogo to="/" />
          <AccountMenu options={options} />
        </>
      );
    }
  }, [isMobile, options]);

  return (
    <Navigation>
      <Wrapper>{content}</Wrapper>
    </Navigation>
  );
};
